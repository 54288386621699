import requestStatus from '@src/utils/helpers/requestStatus';
import types from './types';
import initialState from './initialState';

const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_ARTICLE_FULFILLED: {
      return {
        ...state,
        id: action.response.id,
        error: action.response.error,
        title: action.response.title,
        lead: action.response.lead,
        topImage: action.response.topImage,
        topEmbed: action.response.topEmbed,
        authors: action.response.authors,
        categories: action.response.categories,
        tags: action.response.tags,
        bodyContent: action.response.bodyContent,
        published: action.response.published,
        updated: action.response.updated,
        topVideo: action.response.topVideo,
        canonicalUrl: action.response.canonicalUrl,
        metaData: action.response.metaData,
        isPaid: action.response.isPaid,
        salesposters: action.response.salesposters,
        requestStatus: requestStatus.fulfilled,
        noindex: action.response.noindex
      };
    }
    case types.CREATE_ARTICLE_PENDING: {
      return {
        ...state,
        requestStatus: requestStatus.pending
      };
    }
    case types.CREATE_ARTICLE_REJECTED: {
      return {
        ...state,
        error: {
          message: action.error.message
        },
        requestStatus: requestStatus.rejected
      };
    }
    case types.PUT_POLL_PENDING: {
      return {
        ...state,
        requestStatus: requestStatus.pending
      };
    }
    case types.PUT_POLL_FULFILLED: {
      return {
        ...state,
        requestStatus: requestStatus.fulfilled
      };
    }
    case types.PUT_POLL_REJECTED: {
      return {
        ...state,
        requestStatus: requestStatus.rejected
      };
    }
    case types.CLEAR_ARTICLE: {
      return {
        ...initialState,
        id: state.id
      };
    }
    default:
      return state;
  }
};

export default articleReducer;
