import isEmpty from 'lodash/isEmpty';
import {
  ExperimentIdentifiers,
  getSalesposter
} from '@src/utils/helpers/experiments';
import types from './types';

import parseArticle from './parse';

const articleFulfilled = parsedArticle => ({
  type: types.CREATE_ARTICLE_FULFILLED,
  response: parsedArticle
});

const articlePending = () => ({
  type: types.CREATE_ARTICLE_PENDING
});

const articleRejected = error => ({
  type: types.CREATE_ARTICLE_REJECTED,
  error: {
    message: error.message
  }
});

const pollFulfilled = () => ({
  type: types.PUT_POLL_FULFILLED
});

const pollPending = () => ({
  type: types.PUT_POLL_PENDING
});

const pollRejected = error => ({
  type: types.PUT_POLL_REJECTED,
  error: {
    message: error.message
  }
});

function getArticle(id) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response =>
      dispatch(articleFulfilled(parseArticle(response)));
    const rejected = error => dispatch(articleRejected(error));
    dispatch(articlePending());
    return wsCalls().getArticle(fulfilled, rejected, id);
  };
}

const applySalesposters = (fulfilled, wsCalls, parsedArticle) => {
  const salesposterIdentifiers = parsedArticle?.bodyContent
    ?.filter(content => content.type === ExperimentIdentifiers.ShortSalesWidget)
    .map(content => content.type);

  const uniqueSalesposterIdentifiers = [...new Set(salesposterIdentifiers)];
  const getSalespostersPromises = uniqueSalesposterIdentifiers.map(
    identifier => {
      const category = parsedArticle?.categories?.[0];
      return getSalesposter(
        identifier,
        category,
        wsCalls,
        ExperimentIdentifiers.ShortSalesWidget
      );
    }
  );

  Promise.all(getSalespostersPromises).then(salesposters => {
    fulfilled(salesposters?.filter(salesposter => !isEmpty(salesposter)));
  });

  if (salesposterIdentifiers.length === 0) {
    fulfilled();
  }
};

function getNonMemberArticle(id) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => {
      const parsedArticle = parseArticle(response);
      applySalesposters(
        salesposters => {
          parsedArticle.salesposters = salesposters;
          dispatch(articleFulfilled(parsedArticle));
        },
        wsCalls,
        parsedArticle
      );
    };
    const rejected = error => dispatch(articleRejected(error));
    dispatch(articlePending());
    return wsCalls().getNonMemberArticle(fulfilled, rejected, id);
  };
}

const putPoll =
  (id, { pollId, answerNumber, answersCount }) =>
  (dispatch, _, wsCalls) => {
    const fulfilled = () => dispatch(pollFulfilled());
    const rejected = error => dispatch(pollRejected(error));
    dispatch(pollPending());
    return wsCalls().putArticlePoll(fulfilled, rejected, id, {
      pollId,
      answerNumber,
      answersCount
    });
  };

const updatePoll =
  (id, { pollId, answerNumber, answersCount }) =>
  async dispatch => {
    try {
      await dispatch(putPoll(id, { pollId, answerNumber, answersCount }));
      await dispatch(getNonMemberArticle(id));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

const clearArticle = () => ({ type: types.CLEAR_ARTICLE });

export default {
  getArticle,
  getNonMemberArticle,
  clearArticle,
  updatePoll
};
